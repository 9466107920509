<template>
  <v-dialog
    :width="$store.getters['auth/isStudio'] ? '450' : '500'"
    v-model="dialog"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!list"
        elevation="0"
        v-on="on"
        @click="fetchTattooerTokens"
        block
        height="30px"
        :disabled="disabled"
        v-text="$t('edit', { name: '' })"
      />
    </template>
    <v-card id="Token">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("tattooers.token_payment") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <TokenPayment :tattooer="tattooer"></TokenPayment>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="
            dialog = false;
            $emit('update');
            fetchTattooerTokens();
          "
          style="height: 30px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="save"
          elevation="0"
          style="height: 30px; width: 100px !important"
          ><v-icon small style="margin-right: 5px">$save</v-icon
          >{{ $t("save", { name: "" }) }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ProfileTokenPayment",
  props: ["id", "list", "disabled"],
  data() {
    return {
      dialog: false,
      tattooer: { token_payment: [], tattooer_studios: [] },
    };
  },
  mounted() {
    this.fetchTattooerTokens();
  },
  methods: {
    ...mapActions("tattooers", ["getTattooerPricing", "editTattooerPricins"]),
    fetchTattooerTokens() {
      this.getTattooerPricing(this.id).then((data) => {
        this.tattooer.token_payment = data.token_payment;
        this.tattooer.tattooer_studios = data.tattooer_studios;
      });
    },
    save() {
      this.editTattooerPricins({
        tattooer_id: this.id,
        formData: this.tattooer,
      }).then((data) => {
        this.$alert("Guardado correctamente", "", "");
        this.tattooer.token_payment = data.token_payment;
        this.tattooer.tattooer_studios = data.tattooer_studios;
        this.dialog = false;
        this.$emit("update");
      });
    },
  },
  components: {
    TokenPayment: () => import("@/components/tattooer/TokenPayment"),
  },
};
</script>

<style lang="sass">
#Token
</style>